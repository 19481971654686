import React from 'react';
import './googleads.css'

export default class InGridGoogleAd extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
      <div className='ingrid'>
        <ins className='adsbygoogle'
          style={{ display: 'block', width:"100%", height:"30vh" }}
          data-ad-client='ca-pub-5073631124350142'
          data-ad-slot={this.props.adslot}
          // data-adtest="on"
        //   data-ad-format='auto' 
        //   data-full-width-responsive="true"
          />
      </div>
    );
  }
}