import FormerSuccess from "./farmersuccess";
import React from 'react'

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import './infiniteslider.css'
const { useState, useEffect, useMemo, useRef } = React;


const getItems = () =>
  Array(20)
    .fill(0)
    .map((_, ind) => ({ id: `element-${ind}` }));

const InfiniteItem = (props) => {
  const { item, width } = props;

  return (
    <div className="InfiniteItem" style={{ width }}>
    <div className="InfiniteItem__image">
      <img src={item.img} />
    </div>
    <div className="InfiniteItem__text">{item.name}</div>
  </div>
  );
};

const InfiniteSlider = (props) => {
    const {items} = props;
    // const [items, setItems] = React.useState(getItems);
    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);
  
    const isItemSelected = (id) => !!selected.find((el) => el === id);
  
    const handleClick =
      (id) =>
      ({ getItemById, scrollToItem }) => {
        const itemSelected = isItemSelected(id);
  
        setSelected((currentSelected) =>
          itemSelected
            ? currentSelected.filter((el) => el !== id)
            : currentSelected.concat(id)
        );
      };
  
    return (
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {items.map((item, ind) => (
            <div itemId = {ind} style={{marginLeft: "10px", marginRight: "10px", width: "25vw"}}>
          <FormerSuccess

          videoid="_D8bHWicTug"
          img={item.featured_media.source_url}
          heading={item.title}
          slug={item.slug}
          content="Story of Ayyappa Masagi who is fighting for the cause of water conservation and management relentlessly."
        />
        </div>
        ))}
      </ScrollMenu>
    );
};

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);
  
    return (
        <div className="InfiniteSlider__buttons_Prev">
       <button className="InfiniteSlider__buttonPrev" disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        &#8249;
      </button>
      </div>
    );
  }
  
  function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  
    return (
        <div className="InfiniteSlider__buttons">
       <button className="InfiniteSlider__buttonNext" disabled={isLastItemVisible} onClick={() => scrollNext()}>
        &#8250;
      </button>
      </div>
    );
  }
  
  function Card({ onClick, selected, title, itemId }) {
    const visibility = React.useContext(VisibilityContext);
  
    return (
      <div
        onClick={() => onClick(visibility)}
        style={{
          width: '160px',
        }}
        tabIndex={0}
      >
        <div className="card">
          <div>{title}</div>
          <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
          <div>selected: {JSON.stringify(!!selected)}</div>
        </div>
        <div
          style={{
            height: '200px',
          }}
        />
      </div>
    );
  }

export default InfiniteSlider;