import React from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/layout";
import FormerSuccess from "../components/farmersuccess";
import ModalVideo from 'react-modal-video';
import DonutChart from "react-donut-chart";

import blogbanner from '../images/blogs/banner-4.jpg'

import { StaticQuery, graphql } from "gatsby";

import HeaderLight from "../components/headerLight.js";
import Footer from '../components/footer.js'
import GoogleAd from "../components/GoogleAd";
import InGridGoogleAd from "../components/InGridGoogleAd";
import VerticleAdUnit from "../components/VerticleAdUnit";
import Header from "../components/header";
import InfiniteSlider from "../components/InfiniteSlider";
import Axios from "axios";
import { Spin } from "antd";
require("../css/home.css");
require("../css/how-it-works.css");
require("../css/responsive.scss");

require("../css/responsive.scss");


class Blog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      posts: [],
      latestPost: null,
      recentPosts: [],
      stories: [],
      series: [],
      blogLoading: false
    };
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  parseDateString(ds) {
    let date = ds.split(" ")[0]
    let time = ds.split(" ")[1]
    return new Date(date.split("-")[0], parseInt(date.split("-")[1])-1, date.split("-")[2], time.split(":")[0], time.split(":")[1], time.split(":")[2], 0)
  }

  removeT(date) {
    let dateA = date.split("T")
    return dateA[0] + " " + dateA[1]
  }


  async getMedia(post) {
    if (post._links != null && post._links["wp:featuredmedia"] != null) {
      console.log("MYPOSS-fetur-med", post._links["wp:featuredmedia"])
      let href = post._links["wp:featuredmedia"][0]["href"];
      console.log("MYPOSS-href", href)
      let res = await fetch(href)
      console.log("MYPOSS-fetur-med", res)
      const data = await res.json();
      if (data != null 
        && data.media_details != null 
        && data.media_details.sizes != null 
        && data.media_details.sizes.full != null ) {
          return {source_url : data.media_details.sizes.full.source_url}
      }
    }
    return null
  }

  async fetchPostsAndCategories() {
    fetch(`https://dashboard.nativecircle.in/wp-json/wp/v2/categories`)
    .then(res => res.json())
    .then(res => {
      let catDict = {}
      res.forEach(cat => {
        catDict[cat.id] = cat
      })
      fetch(`https://dashboard.nativecircle.in/wp-json/wp/v2/posts?per_page=100&page=1&orderby=date&order=desc`)
    .then(postRes => postRes.json())
    .then(async postRes => {    
      let stdPosts = []
      console.log("UNQ", postRes)  
      let mapme = postRes.map(async post => {
        let medurl =  await this.getMedia(post)
        return {
          content: post.content != null ? post.content.rendered : "",
          title: post.title != null ? post.title.rendered : "",
          wordpress_id: post.id,
          slug: post.slug,
          featured_media: medurl,
          date: this.removeT(post.date),
          categories: post.categories != null && post.categories.length != 0 ? [{name: catDict[post.categories[0]].name}] : null
        }
      })
      console.log("UNQ MP", mapme)
      
    })
  })
    
  }

  async fetchPostsAndCategoriesAsyncAwait() {
    const cats = await fetch(`https://dashboard.nativecircle.in/wp-json/wp/v2/categories`).then(res => res.json())
    let catDict = {}
    cats.forEach(cat => {
      catDict[cat.id] = cat
    })


    
    const postArray = await fetch(`https://dashboard.nativecircle.in/wp-json/wp/v2/posts?per_page=100&page=1&orderby=date&order=desc`).then(postRes => postRes.json());
    
    let newpostarr = []
    let slugDict = {}
    
    for (let postInd in postArray) {
      let post = postArray[postInd]
      console.log("UNQPOST", post)
        let medurl =  await this.getMedia(post)
        let newpost = {
          content: post.content != null ? post.content.rendered : "",
          title: post.title != null ? post.title.rendered : "",
          wordpress_id: post.id,
          slug: post.slug,
          featured_media: medurl,
          date: this.removeT(post.date),
          categories: post.categories != null && post.categories.length != 0 ? [{name: catDict[post.categories[0]].name}] : null
        }
        newpostarr.push(newpost)
        slugDict[newpost.slug] = newpost
    }


      let latestPost = null;
      let latestPostDate = null;

      let postsSorted = [];
      let posts = []

      newpostarr.forEach((post, postIndex) => {
        console.log("MYDATE",this.parseDateString(post.date))
        console.log("MYPOST",post)

        
        let postDate = this.parseDateString(post.date)
        if (latestPostDate == null) {
          latestPostDate = postDate
          latestPost = post
        } else if (postDate > latestPostDate) {
          latestPostDate = postDate
          latestPost = post
        }
        postsSorted.push({...post, postDate});
        posts.push(<div className="col-sm-4">
        <FormerSuccess
          videoid="_D8bHWicTug"
          img={post.featured_media.source_url}
          heading={post.title}
          slug={post.slug}
          content="Story of Ayyappa Masagi who is fighting for the cause of water conservation and management relentlessly."
        />
      </div>)
      if ((postIndex + 1) % 3 == 0) {
        posts.push(<InGridGoogleAd adslot="9333673731" />)
      }
      })

        let stories = []
        let series = []
        postsSorted = postsSorted.sort((a,b) => a.postDate > b.postDate);

        postsSorted.forEach(post => {
          if (post.categories != null && post.categories[0] != null) {
            if (post.categories[0].name == "Stories") {
              stories.push(post)
            }
            else if (post.categories[0].name == "Series") {
              series.push(post)
            }
          }
        })


        this.setState({stories: stories, series: series})

        let recentPost = postsSorted.slice(0, Math.min(postsSorted.length, 3))
        let recentPostUI = []
        recentPost.forEach(post => {
          recentPostUI.push(<div className="col-sm-4">
        <FormerSuccess
          videoid="_D8bHWicTug"
          img={post.featured_media.source_url}
          heading={post.title}
          slug={post.slug}
          content="Story of Ayyappa Masagi who is fighting for the cause of water conservation and management relentlessly."
        />
      </div>)
        })

        console.log("SORTPOST", recentPostUI)
        this.setState({recentPosts: recentPostUI})

        console.log("SORT", recentPost)

          console.log("MYPOSTLATE",latestPost)
         this.setState({posts: posts}) 
         this.setState({latestPost: latestPost}) 
         window.localStorage.setItem(
          "wordpressPostSlugDict",
          JSON.stringify(slugDict)
        );     

        this.setState({blogLoading: false})


    
  }

  componentDidMount() {
    this.setState({blogLoading: true})
    this.fetchPostsAndCategoriesAsyncAwait()
    let posts = []
    let postsDict = {}

    let postsSlugDict = {}

    if(this.props.data.allWordpressWpComments != null) {
      this.props.data.allWordpressWpComments.nodes.forEach(comm => {
        if (postsDict[comm.post] != undefined || postsDict[comm.post] != null) {
          postsDict[comm.post].push(comm)
        }
      })
      window.localStorage.setItem(
        "allWordpressComments",
        JSON.stringify(postsDict)
      );
    }

  }

   testingItems = [
    { name: "Video 1", img: "https://via.placeholder.com/225x160" },
    { name: "Video 2", img: "https://via.placeholder.com/225x160" },
    { name: "Video 3", img: "https://via.placeholder.com/225x160" },
    { name: "Video 4", img: "https://via.placeholder.com/225x160" },
    { name: "Video 5", img: "https://via.placeholder.com/225x160" },
    { name: "Video 6", img: "https://via.placeholder.com/225x160" },
    { name: "Video 7", img: "https://via.placeholder.com/225x160" },
    { name: "Video 8", img: "https://via.placeholder.com/225x160" },
    { name: "Video 9", img: "https://via.placeholder.com/225x160" }
  ];

  render() {
    return (
      <div>
        
        <Header pathname="/blog" />
        <Spin  tip="Loading..."
          spinning={this.state.blogLoading}
          wrapperClassName="spinClass">
        <div className="farmerBanner-blog">
          <div className="col-sm-12">
            <div className="forfarmerHeadtitlecontainer">
            <h3 className="forfarmerHeadtitle">
              Stories of Unsung heros, sustainable practices, changemakers, innovations around forestry, agriculture and horticulture. <br/>The list goes on...
            </h3>
            </div>
            {/* <h4 className="forfarmerHeadsubtitle">
              By Native Circle 
            </h4> */}
            <div className="col-sm-6 farmerPoints">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 farmerbtnMain">
                    {/* <button className="downloadfarmer" href="">Download the App <img className="iconCheckout" src="https://res.cloudinary.com/djoztpm50/image/upload/v1551253843/Native%20Circle/path-down.png"/></button> */}

                    <a
                      href="https://www.youtube.com/channel/UCY7_eKI3mv_mVCnhFFpWXEA"
                      target="_blank"
                    >

                      {/* <button className="watchVideoBanner">
                        Watch videos{" "}
                        <img
                          className="iconCheckout"
                          src="https://res.cloudinary.com/djoztpm50/image/upload/v1551253847/Native%20Circle/path-video.png"
                        />
                      </button> */}

                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 pad0 HowitworksMainDiv">
          <div style={{backgroundImage: `url(${blogbanner})`, 
          marginTop:"-5vh",
          width: "100vw", 
          height: "60vh", 
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          paddingTop:"5vh"}}>
            <div style={{width: "100%", display : "flex", flexDirection: "column", alignItems: "flex-start"}}>
              <h1 style={{fontSize: "1.5rem", color: "#fff", backgroundColor: "#a3996e", fontWeight: "bold", border: "2px solid #a3996e", padding: "4px 15px 4px 15px"}}>
                LATEST ARTICLE
                </h1>
            </div>
          <div className="banner-post-wrapper">
            {/* <div className="row" >
                <div className="col-sm-4" style={{padding: 0}}>
                <h5 className="connectFarmerHead">{}</h5>
                </div>
              </div> */}

              <div className="banner-post-container">
              

              {this.state.latestPost != null 
                && <div className="banner-post-content-container">
                <h5 className="banner-post-title">{this.state.latestPost.title}</h5>
                <div dangerouslySetInnerHTML={{__html : this.state.latestPost.content.substring(0,300) + "..."}} />
                <p className="latest-post-date">Posted On : {this.state.latestPost.date}</p>
                <Link to={"/blogpost/" + this.state.latestPost.slug}>
                <button className="blogReadMore" style={{margin: "0px !important"}}>
                      Read more{" "}
                      <img
                        className="watchVideoIcon"
                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                      />
                    </button>
                    </Link>
              </div>}

              {this.state.latestPost != null 
                && <div className="banner-post-img-container">
                    <img className="banner-img" src={this.state.latestPost.featured_media.source_url} />
                    </div>}
                    
              </div>

              </div>

          </div>
          <div className="blog-container">
            <div className="col-sm-12 pad0">

            {this.state.stories.length != 0 && <div className="latest-post-wrapper">
            <div className="row" style={{marginTop: "10vh"}}>
                <div className="col-sm-4" style={{padding: 0, display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                <h5 style={{margin: 0, fontSize: "1.5rem", color: "#fff", backgroundColor: "#a3996e", fontWeight: "bold", border: "2px solid #a3996e", padding: "4px 15px 4px 15px"}}>STORIES</h5>
                </div>
              </div>
                

              <div style={{ backgroundColor: "#e6dfbc", padding: "1.5rem 0 0 0"}}>
              
                  {/* <div style={{display: "flex", width: "80%"}}>
                    {this.state.stories}
                    </div> */}
                    <InfiniteSlider
                      title={"Videos"}
                      items={this.state.stories}
                      itemsPerScroll={3}
                      height={170}
                      speedMs={500}
                    />
                  </div>

              </div>}

              {this.state.series.length != 0 && <div className="latest-post-wrapper">
            <div className="row" style={{marginTop: "10vh"}}>
                <div className="col-sm-4" style={{padding: 0, display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                <h5 style={{margin: 0, fontSize: "1.5rem", color: "#fff", backgroundColor: "#a3996e", fontWeight: "bold", border: "2px solid #a3996e", padding: "4px 15px 4px 15px"}}>SERIES</h5>
                </div>
              </div>
                

              <div style={{ backgroundColor: "#e6dfbc", padding: "1.5rem 0 0 0"}}>
              
                  {/* <div style={{display: "flex", width: "80%"}}>
                    {this.state.stories}
                    </div> */}
                    <InfiniteSlider
                      title={"Videos"}
                      items={this.state.series}
                      itemsPerScroll={3}
                      height={170}
                      speedMs={500}
                    />
                  </div>

              </div>}



              {/* <div style={{padding: "2rem", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <GoogleAd  />
              </div> */}

            <div className="latest-post-wrapper">
            <div className="row" style={{marginTop: "10vh"}}>
                <div className="col-sm-4" style={{padding: 0, display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                <h5 style={{margin: 0, fontSize: "1.5rem", color: "#fff", backgroundColor: "#a3996e", fontWeight: "bold", border: "2px solid #a3996e", padding: "4px 15px 4px 15px"}}>RECENT ARTICLES</h5>
                </div>
              </div>
                

              <div className="row" style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#e6dfbc", padding: "1.5rem 0 0 0"}}>
                  <div style={{display: "flex", width: "80%"}}>
                    {this.state.recentPosts}
                    </div>
                  </div>

              </div>
              

              <div style={{padding: "2rem", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <GoogleAd  />
              </div>



              <div className="all-blogs-wrapper">
                {/* <VerticleAdUnit pos="left" adslot="5049161406" adwidth="10vw"/> */}
                <div style={{width : "100vw", alignSelf: "center", display: "flex", flexDirection : "column"}}>
                  <div style={{marginTop: "1vh"}}>
                  <div  style={{padding: 0, display: "flex", flexDirection: "column", alignItems: "center", width: "100%",}}>
                <h5 style={{margin: 0, fontSize: "1.5rem", color: "#fff", backgroundColor: "#a3996e", fontWeight: "bold", border: "2px solid #a3996e", padding: "4px 15px 4px 15px"}}>
                  ALL ARTICLES</h5>
                </div>
                  </div>
                  
                  <div className="row" style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "2rem"}}>
                    <div className="row" style={{width: "90%"}}>
                      {this.state.posts}
                    </div>
                  </div>
                </div>
                {/* <VerticleAdUnit pos="right" adslot="5049161406" adwidth="10vw"/> */}
              </div>
            </div>

          </div>
        </div>

        <Footer />

        </Spin>
      </div>
    );
  }
}

export default Blog;

export const postsQuery = graphql`
query postsAndCommentsQuery {
  allWordpressPost {
    nodes {
      content
      slug
      title
      wordpress_id
      comment_status
      date(formatString: "yyyy-MM-DD HH:mm:ss")
      featured_media {
        source_url
      }
      categories {
        name
      }
    }
  }
}
`;
